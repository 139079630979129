<template>
  <v-row>
    <v-col
      cols="12"
      sm="6"
    >
      <v-row 
        style="padding: 0;margin: 0;cursor: pointer;"
      >
        <v-col
          cols="12"
          md="4"
          style="padding-left: 0;"
        >
        </v-col>
        <v-col
          cols="12"
          md="4"
          style="padding-left: 0;"
        >
          <b>Clase</b>
        </v-col>
        <v-col
          cols="12"
          md="4"
          style="padding-left: 0;"
        >
          <b>Tipo</b>
        </v-col>
      </v-row>
      <v-row 
        v-for="item in items" 
        :key="item.ID" 
        v-bind:class="'itemDraggable'"
        style="padding: 0;margin: 0;cursor: pointer;border-bottom: 1px solid #CCCCCC80;"
      >
        <v-col
          cols="12"
          md="4"
          style="padding-left: 0;"
        >
          <a @click="handleEdit(item.ID)">{{ item.Name && getTranslation(item.Name, locale).length >= 1 ? getTranslation(item.Name, locale) : item.ID }}</a>
        </v-col>
        <v-col
          cols="12"
          md="4"
          style="padding-left: 0;"
        >
          {{ item.ClassName }}
        </v-col>
        <v-col
          cols="12"
          md="4"
          style="padding-left: 0;"
        >
          {{ item.FieldType }}
        </v-col>
      </v-row>
    </v-col>
    <v-col
      v-if="!editedItem"
      cols="12"
      sm="6"
    >
      <v-btn block :elevation="0" color="primary" @click="handleAdd">
        {{ $t('common.add', locale)}}
      </v-btn>
    </v-col>
    <v-col
      v-else
      cols="12"
      sm="6"
    >
      <v-row>
        <v-col
          cols="12"
          sm="12"
          style="padding: 10px 20px;"
        >
          <translate
            v-model="editedItem.Name"
            :languages="languages"
            :label="$t('booking.name', locale)"
            type="text"
          />
        </v-col>
        <v-col
          cols="12"
          sm="12"
        >
          <v-select
            v-model="editedItem.FieldType"
            :items="types"
            label="Tipo campo"
            outlined
            hide-details
          />
        </v-col>
        <v-col
          cols="12"
          sm="12"
          style="padding: 10px 10px 10px 10px;"
        >
          <v-text-field
            label="Nombre clase"
            v-model="editedItem.ClassName"
            hide-details
            outlined
          />
        </v-col>
        <v-col
          v-if="['imagen', 'polaroid'].indexOf(editedItem.FieldType) >= 0"
          cols="12"
          sm="6"
          style="padding: 10px 10px 10px 10px;"
        >
          <v-text-field
            label="Width"
            v-model="editedItem.Width"
            hide-details
            outlined
          />
        </v-col>
        <v-col
          v-if="['imagen', 'polaroid'].indexOf(editedItem.FieldType) >= 0"
          cols="12"
          sm="6"
          style="padding: 10px 10px 10px 10px;"
        >
          <v-text-field
            label="Height"
            v-model="editedItem.Height"
            hide-details
            outlined
          />
        </v-col>
        <v-col
          cols="12"
          sm="12"
          style="padding: 10px 10px 10px 10px;"
        >
          <v-text-field
            label="ORD"
            v-model="editedItem.ORD"
            hide-details
            outlined
          />
        </v-col>
        <v-col
          cols="12"
          sm="12"
        >
          <action-buttons
            v-if="editedItem"
            :id="editedItem.ID"
            :onDeleteItem="handleDelete"
            :onSaveItem="handleSave"
            :onClose="handleClose"
            :locale="locale"
            :icon="false"
          />
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>
<script>
const defaultItem = {
  Name: { es: ''},
  ClassName: 'class-name',
  Type: 'text',
}
import api from '@/services/api'
import utils from '@/services/utils'
import ActionButtons from '@/components/ActionButtons'
import Translate from '@/components/Translate'
export default {
  components: {
    ActionButtons,
    Translate,
  },
  props: {
    workspaceID: {
      type: String,
      required: true,
    },
    editedID: {
      type: String,
      required: true
    },
    locale: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    items: [],
    editedItem: null,
  }),
  computed: {
    types () {
      return ['imagen', 'polaroid', 'text', 'richtext']
    },
    languages () {
      return ['es', 'en', 'de', 'fr', 'ca', 'it']
    },
  },  
  watch: {
    editedID () {
      this.handleGetData()
    },
  },
  mounted () {
    this.handleGetData()
  },
  methods: {
    getTranslation (v, locale) {
      return utils.getTranslation(v, locale)
    },
    handleGetData () {
      this.items = []
      if (!this.editedID) return
      api.getAllWithoutLimit ('dviewcontent', `v1/system/workspaces/${this.workspaceID}/custom-content-types/${this.editedID}/fields/`)
        .then(response => {
          if (!response) return
          this.items = response.map(item => {
            item.Name = item.Name ? JSON.parse(item.Name) : { es: '' }
            return item
          })
        })
        .catch(error => {
          alert(error)
        })
    },
    handleEdit (v) {
      if (!v) return
      api.getItem ('dviewcontent', `v1/system/workspaces/${this.workspaceID}/custom-content-types/${this.editedID}/fields/`, v)
        .then(response => {
          if (!response) return
          response.Name = response.Name ? JSON.parse(response.Name) : {es: ''}
          this.editedItem = response
        })
        .catch(error => {
          alert(error)
        })
    },
    handleAdd () {
      this.editedItem = JSON.parse(JSON.stringify(defaultItem))
      this.editedItem.CustomContentTypeID = this.editedID
    },
    handleSave () {
      if (!this.editedItem.ID) {
        api.addItem ('dviewcontent', `v1/system/workspaces/${this.workspaceID}/custom-content-types/${this.editedID}/fields`, this.editedItem)
          .then(response => {
            console.log(response)
            this.handleGetData()
            this.editedItem = null
          })
          .catch(error => {
            alert(error)
          })
      } else {
        api.updateItem ('dviewcontent', `v1/system/workspaces/${this.workspaceID}/custom-content-types/${this.editedID}/fields/`, this.editedItem.ID, this.editedItem)
          .then(response => {
            console.log(response)
            this.handleGetData()
            this.editedItem = null
          })
          .catch(error => {
            alert(error)
          })
      }
    },
    handleDelete () {
      api.deleteItem ('dviewcontent', `v1/system/workspaces/${this.workspaceID}/custom-content-types/${this.editedID}/fields/`, this.editedItem.ID)
        .then(() => {
          this.handleGetData()
          this.editedItem = null
        })
        .catch(error => {
          alert(error)
        })
    },
    handleClose () {
      this.editedItem = null
    },
  },
}
</script>

